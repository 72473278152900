import { setAttributes, setForm, setPrice } from '..';
import { RESET_STEP_CAROUSEL } from '../../../utils/constants';
import { getDefaultAttributesValues } from '../../../utils/functions';

export const configurationReducers = {
  setProduct(state, action) {
    state.configuration.product = action.payload;
  },
  setForm(state, action) {
    state.configuration.form = action.payload;
  },
  setAttributes(state, action) {
    const newAttributes = Object.assign(
      {},
      state.configuration.attributes,
      action.payload
    );
    state.configuration.attributes = { ...newAttributes };
  },
  setValidAttributes(state, action) {
    const newValidAttributes = action.payload.map((attribute) => {
      return {
        ...attribute,
      };
    });
    state.configuration.dataDrivenConfiguratorExtensionStatus.validAttributes =
      [...newValidAttributes];
  },
  setSku(state, action) {
    state.configuration.dataDrivenConfiguratorExtensionStatus.sku =
      action.payload;
  },
  setFormValidAttributes(state, action) {
    state.configuration.formValidAttributes = action.payload;
  },
  setMetadata(state, action) {
    state.configuration.metadata = action.payload;
  },
  setPrice(state, action) {
    state.configuration.price = action.payload;
  },
  setName(state, action) {
    state.configuration.name = action.payload;
  },
  setNestedAttributesState(state, action) {
    state.configuration.nestedAttributes = action.payload;
  },
  setNestedAttributeAddressState(state, action) {
    state.configuration.nestedAttributeAddress = action.payload;
  },

  setActiveAttribute(state, action) {
    state.configuration.activeAttribute = action.payload;
  },
  setDependencies(state, action) {
    state.configuration.dependencies = action.payload;
  },
};

const setDataDrivenFewConfiguration = async (attributes) => {
  for (const [key, value] of Object.entries(attributes)) {
    await window.dataDrivenConfigurator.setConfiguration({
      [key]: value,
    });
  }
};

export const updateModelPresentationOnDataDriven =
  () => async (dispatch, getState) => {
    const { threekit } = getState();
    const currentModelPresentation =
      threekit?.configuration?.attributes?.['Model Presentation']?.value;
    await window.dataDrivenConfigurator.setConfiguration({
      'Model Presentation': currentModelPresentation,
    });
  };

export const setConfiguration = (config) => async (dispatch, getState) => {
  let preppedConfig = config || {};

  //dispatch(setPlayerLoading(true));
  console.log('preppedConfig!!!', preppedConfig);

  if (Object.keys(preppedConfig).length === 1) {
    await window.dataDrivenConfigurator.setConfiguration(preppedConfig);
  } else {
    await setDataDrivenFewConfiguration(preppedConfig);
  }
  const updatedAttributes = await window.threekit.controller.getAttributes();

  dispatch(setForm(window.threekit.controller.getForm(preppedConfig)));
  dispatch(setAttributes({ ...updatedAttributes }));
  dispatch(setPrice(window.threekit.controller.getPrice()));
  //dispatch(setPlayerLoading(false));
};

export const resetFormToDefaultValues =
  ({ newStep = RESET_STEP_CAROUSEL, attributesNamesToReset }) =>
  async (dispatch, getState) => {
    const attributesData = attributesNamesToReset
      ? Object.fromEntries(
          Object.entries(
            getState()?.threekit?.configuration?.attributes
          )?.filter(([key, value]) =>
            attributesNamesToReset?.includes(value?.name)
          )
        )
      : getState()?.threekit?.configuration?.attributes;
    const defaultAttributesValues = getDefaultAttributesValues(attributesData);
    dispatch(setConfiguration(defaultAttributesValues));
  };
