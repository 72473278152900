import React, { useEffect, useState } from 'react';
import {
  Container,
  ConfigurationSection,
  ContentWrapper,
} from './ClientPage.styles';
import { LVLoader, PhotosDisplayer } from '../../components';
import TopSection from '../../components/TopSection/TopSection';
import { usePageTitle, useReadableConfiguration } from '../../hooks';
import ConfigurationSectionContent from '../components/ConfigurationSectionContent';

const ClientPage = () => {
  usePageTitle({ page: 'client' });
  const [attributesToDisplay, setAttributesToDisplay] = useState();
  const { readableConfiguration, lvCustomData, attachments, isLoading, error } =
    useReadableConfiguration();
  useEffect(() => {
    if (readableConfiguration) {
      console.log(readableConfiguration);
      setAttributesToDisplay(Object.entries(readableConfiguration));
    }
  }, [readableConfiguration]);

  if (error) {
    return <div style={{ margin: '20px' }}>{error}</div>;
  }

  if (isLoading) {
    return <LVLoader display={isLoading} />;
  }

  return (
    attributesToDisplay && (
      <>
        <Container isLoading={isLoading}>
          <PhotosDisplayer photos={attachments} setPhotoLoaded={isLoading} />
          <ContentWrapper>
            <TopSection productData={lvCustomData} />
            <ConfigurationSection>
              <ConfigurationSectionContent
                attributesToDisplay={attributesToDisplay}
              />
            </ConfigurationSection>
          </ContentWrapper>
        </Container>
      </>
    )
  );
};

export default ClientPage;
